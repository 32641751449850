<template>
  <v-app id="app">
    <v-navigation-drawer v-model="drawer" clipped fixed app class="nav body-2 d-print-none" width="360">
      <v-list>
      <v-divider></v-divider>
      <v-list-item to="/datenschutzerklarung" key="datenschutzerklarung">
          <v-list-item-content>Datenschutzerklärung</v-list-item-content>
      </v-list-item>
      <v-list-item to="/impressum" key="impressum">
            <v-list-item-content>Impressum</v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
        <v-list-group :value="false" v-for="Fach in Fächer" :key="Fach.title">
          <template v-slot:activator>
            <v-list-item class="pa-0">
              <v-list-item-title class="body-2">{{Fach.title}}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-group no-action sub-group :value="false" v-for="Thema in Fach.themen" :key="Thema.title">
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-title class="body-2">{{Thema.title}}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item v-for="Lernzettel in Thema.lernzettel" :key="Lernzettel.link" :to="Lernzettel.link">
              <v-list-item-content class="body-2">{{Lernzettel.title}}</v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      <v-divider></v-divider>
      <v-list-item href="https://zweig.lernzettel.org/" target="_blank" rel="noopener">
        <v-list-item-icon>
            <v-icon>{{books}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>Stefan Zweigs Werke</v-list-item-content>
      </v-list-item>
      <v-list-item href="https://polar.lernzettel.org/" target="_blank" rel="noopener">
        <v-list-item-icon>
            <v-icon>{{rocket}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>PolarClock</v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      </v-list>
      <div class="tc"><p>Minimalistische Webanalyse durch <a href="https://plausible.io/" target="_blank" rel="noopener">Plausible</a></p></div>
      <v-divider></v-divider>
    </v-navigation-drawer>

    <v-app-bar app fixed dense flat clipped-left color="white" class="tb d-print-none">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" aria-label="Menü"></v-app-bar-nav-icon>
      <router-link to="/"><v-toolbar-title to="/">Lernzettel</v-toolbar-title></router-link>
      <v-spacer></v-spacer>
      <v-btn icon href="https://ko-fi.com/lernzettel" aria-label="spenden" target="_blank" rel="noopener">
              <v-icon>{{spenden}}</v-icon>
      </v-btn>
      <v-btn icon v-if="sharable()" @click="share" aria-label="teilen">
              <v-icon>{{sharer}}</v-icon>
      </v-btn>
      <v-btn icon @click="print" aria-label="drucken">
              <v-icon>{{printer}}</v-icon>
      </v-btn>
      <v-btn icon v-on:click="navigate" aria-label="Suche">
              <v-icon>{{magnify}}</v-icon>
      </v-btn>
    </v-app-bar>

    <vue-progress-bar></vue-progress-bar>
    
     <v-content class="content">
      <v-container fluid fill-height>
        <v-layout>
          <v-flex>
            <!--<v-alert v-if="showwarning" class="d-print-none" outlined type="info" elevation="10" dense dismissible>Mit der Nutzung dieser Seite erklären Sie sich mit der Verwendung von Google Analytics bzw. der Datenschutzerklärung für einverstanden. | <router-link to="/datenschutzerklarung">Datenschutzerklärung</router-link> | <router-link to="/funktionen/google-analytics-deaktivieren">Google Analytics deaktivieren</router-link></v-alert>-->
            <main>
              <router-view id="print"></router-view>
            </main>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
    <!--<div v-if="this.$Progress.$vm.RADON_LOADING_BAR.percent==100">{{showwarning=true}}</div>-->
  </v-app>
</template>

<style scoped>
.tc{
  text-align: center;
}
@media print {
  .content {padding: 0 !important; z-index: 10;}
}
</style>


<script>
  import { mdiShareVariant } from '@mdi/js'
  import { mdiPrinter } from '@mdi/js'
  import { mdiMagnify } from '@mdi/js'
  import { mdiBookMultiple } from '@mdi/js'
  import { mdiClock } from '@mdi/js'
  import { mdiPiggyBank } from '@mdi/js'
  export default {
    data: () => ({
      sharer: mdiShareVariant,
      printer: mdiPrinter,
      magnify: mdiMagnify,
      books: mdiBookMultiple,
      rocket: mdiClock,
      spenden: mdiPiggyBank,
      drawer: null,
      printer_off: true,
      showwarning: false,
      Fächer: [
        {title: 'Religion', themen: [
            {title: 'Gottesbeweise', lernzettel: [
              {title: 'Kurze Einführung in die Gottesbeweise', link: '/kurze-einfuhrung-in-die-gottesbeweise'},
              {title: 'Ontologischer Gottesbeweis', link: '/ontologischer-gottesbeweis'},
              {title: 'Thomas von Aquin: quinque viae ad deum', link: '/thomas-von-aquin-quinque-viae-ad-deum'},
              {title: 'Pascalsche Wette', link: '/pascalsche-wette'},
              {title: 'Hans Küng und die innere Rationalität des Glaubens', link: '/hans-kung-und-die-innere-rationalitat-des-glaubens'},
              {title: 'Kants Kritik an den Gottesbeweisen', link: '/kants-kritik-an-den-gottesbeweisen'},
              {title: 'Kants moralischer Gottesbeweis', link: '/kants-moralischer-gottesbeweis'},
              {title: 'Weitere Gottesbeweise', link: '/weitere-gottesbeweise'},
              {title: 'Zeitstrahl der Gottesbeweise', link: '/zeitstrahl/gottesbeweise'}
            ]},
            {title: 'Religionskritik', lernzettel: [
              {title: 'Einführung', link: '/einfuhrung'},
              {title: 'Religionskritik nach Feuerbach', link: '/religionskritik-nach-feuerbach'},
              {title: 'Religionskritik nach Marx', link: '/religionskritik-nach-marx'},
              {title: 'Religionskritik nach Nietzsche', link: '/religionskritik-nach-nietzsche'},
              {title: 'Religionskritik nach Freud', link: '/religionskritik-nach-freud'},
              {title: 'Religionskritik nach Russell', link: '/religionskritik-nach-russell'},
              {title: 'Dietrich Bonhoeffer und die Zukunft des Christentums', link: '/dietrich-bonhoeffer-und-die-zukunft-des-christentums'},
              {title: 'Weitere Ansätze', link: '/weitere-ansatze'},
              {title: 'Hans Küng - Stellungnahme zur Religionskritik', link: '/hans-kung-stellungnahme-zur-religionskritik'},
              {title: 'Zeitstrahl der Religionskritik', link: '/zeitstrahl/religionskritik'},
              {title: 'Theodizee-Problem', link: '/theodizee-problem'}
            ]},
            {title: 'Freiheit und Determinismus', lernzettel: [
              {title: 'Einleitung in das Thema Freiheit und Freiheitsbegriffe', link: '/einleitung-in-das-thema-freiheit-und-freiheitsbegriffe'},
              {title: 'Von der Freiheit eines Christenmenschen', link: '/von-der-freiheit-eines-christenmenschen'},
              {title: 'Freiheit in der Aufklärung', link: '/freiheit-in-der-aufklarung'},
              {title: 'Weitere Ansätze zum Thema Freiheit', link: '/weitere-ansatze-zum-thema-freiheit'},
              {title: 'Einführung in den Determinismus und Arten des Determinismus', link: '/einfuhrung-in-den-determinismus-und-arten-des-determinismus'},
              {title: 'Theologischer Determinismus', link: '/theologischer-determinismus'},
              {title: 'Grenzen des Determinismus', link: '/grenzen-des-determinismus-und-adaquater-determinismus'},
              {title: 'Entstehungstheorien des Bewusstseins', link: '/entstehungstheorien-des-bewusstseins'},
              {title: 'Vereinbarkeit von Freiheit und Determinismus', link: '/vereinbarkeit-von-freiheit-und-determinismus'},
              {title: 'Wolf Singer – Wir sollten aufhören von Freiheit zu reden', link: '/wolf-singer-wir-sollten-aufhoren-von-freiheit-zu-reden'}
            ]},
            {title: 'Rechtfertigungslehre', lernzettel: [
              {title: 'Zusammenfassung zur Rechtfertigungslehre', link: '/zusammenfassung-zur-rechtfertigungslehre'},
              {title: 'Vertiefung zu Paulus', link: '/vertiefung-zu-paulus'}
            ]},
            {title: 'Ethik', lernzettel: [
              {title: 'Grundbegriffe der Ethik', link: '/grundbegriffe-der-ethik'},
              {title: 'Gewissen', link: '/gewissen'},
              {title: 'Urteilsbildung', link: '/urteilsbildung'},
              {title: 'Stufen des moralischen Urteils - Lawrence Kohlberg', link: '/stufen-des-moralischen-urteils-lawrence-kohlberg'},
              {title: 'Pflichtethik', link: '/pflichtethik'},
              {title: 'Die Würde des Menschen', link: '/die-wurde-des-menschen'},
              {title: 'Würde am Beispiel des Embryos', link: '/wurde-am-beispiel-des-embryos'}
            ]}
            ]
            },
          {title: 'Deutsch', themen: [
            {title: 'Methodik', lernzettel: [
              {title: 'Textgebundene Erörterung', link: '/textgebundene-erorterung-aspekte-aufbau-formulierungshilfe'},
              {title: 'Aufbau eines Gedichtvergleichs', link: '/aufbau-eines-gedichtvergleichs'},
              {title: 'Gedichtformen', link: '/gedichtformen'},
              {title: 'Reimschema', link: '/reimschema'},
              {title: 'Metrum', link: '/metrum'},
              {title: 'Kadenz', link: '/kadenz'},
              {title: 'Rhythmus', link: '/rhythmus'},
              {title: 'Satzbau', link: '/satzbau'},
              {title: 'Analyse eines Auszuges aus einem Adoleszensroman', link: '/aufbau-der-analyse-eines-auszuges-aus-einem-adoleszensroman'}
            ]},
            {title: 'Exilliteratur', lernzettel: [
              {title: 'Exilliteratur', link: '/exilliteratur'},
              {title: 'PowerPoint Präsentation: Exilliteratur', link: '/powerpoint-prasentation-exilliteratur'}
            ]},
            {title: 'Adoleszenz', lernzettel: [
              {title: 'Definition der Adoleszenz', link: '/definition-der-adoleszenz'},
              {title: 'Typen des Adoleszenzromans', link: '/typen-des-adoleszenzromans'},
              {title: 'Entwicklungsaufgaben in der Adoleszenz', link: '/entwicklungsaufgaben-in-der-adoleszenz'},
              {title: 'Initiationsreise im Adoleszenzroman', link: '/initiationsreise-im-adoleszenzroman'},
              {title: 'Analyse eines Auszuges aus einem Adoleszensroman', link: '/aufbau-der-analyse-eines-auszuges-aus-einem-adoleszensroman'},
              {title: 'Aspekte zur Analyse eines Auszugs aus Unterm Rad', link: '/aspekte-zur-analyse-eines-auszugs-aus-dem-6-kapitel-aus-unterm-rad-von-hermann-hesse'},
              {title: 'Analyse eines Auszugs aus Unterm Rad', link: '/analyse-eines-auszugs-aus-dem-6-kapitel-aus-unterm-rad-von-hermann-hesse'},
              {title: 'Präsentation: Fast Genial – Eltern-Kind-Verhältnis', link: '/prasentation-fast-genial-eltern-kind-verhaltnis'},
              {title: 'Rezension zu Fast Genial (kurz)', link: '/rezension-zu-fast-genial-kurz'}
            ]},
            {title: 'Sprachwandel und Sprachkritik', lernzettel: [
              {title: 'Wichtige Begriffe zu Sprachwandel und Sprachkritik', link: '/wichtige-begriffe-zu-sprachwandel-und-sprachkritik-mit-erklarung'},
              {title: 'Die Sprechschreibe – textgebundene Erörterung', link: '/die-sprechschreibe-textgebundene-erorterung'}
            ]},
            {title: 'Expressionismus', lernzettel: [
              {title: 'Expressionismus', link: '/expressionismus'},
              {title: 'Die Dämmerung – Alfred Lichtenstein', link: '/die-dammerung-alfred-lichtenstein'}
            ]},
            {title: 'Weimarer Klassik', lernzettel: [
              {title: 'Weimarer Klassik', link: '/weimarer-klassik'},
              {title: 'Ist Goethes ,,Das Göttliche“ klassisch?', link: '/ist-goethes-das-gottliche-klassisch'}
            ]},
            {title: 'Weiteres', lernzettel: [
              {title: 'Big-Five-Modell', link: '/big-five-modell'},
              {title: 'Präsentation zum fünften Auszug aus Maria Stuart', link: '/prasentation-zum-funften-auszug-aus-maria-stuart-schiller'},
              {title: 'Rede zur Halbzeit der Oberstufe', link: '/rede-zur-halbzeit-der-oberstufe'},
              {title: 'Wie schrieb Franz Kafka?', link: '/wie-schrieb-franz-kafka'},
              {title: 'Präsentation: Wie schrieb Franz Kafka?', link: '/prasentation-wie-schrieb-franz-kafka'},
              {title: 'Antwort auf Tabula rasa von Christa Wolf', link: '/antwort-auf-christa-wolfs-these-des-urteilsvermogens-durch-prosa-aus-dem-aufsatz-tabula-rasa'},
              {title: 'Rezension zu Verbrechen und Strafe von Fjodor Dostojewski', link: '/rezension-zu-verbrechen-und-strafe-von-fjodor-dostojewski'},
              {title: 'Digitale Medien – Fluch oder Segen?', link: '/digitale-medien-fluch-oder-segen'}
            ]}
          ]},
          {title: 'Englisch', themen: [
            {title: 'Phrasen und Sprache', lernzettel: [
              {title: 'Englische Phrasen zur Verbesserung der Sprache', link: '/englische-phrasen-zur-verbesserung-der-sprache'},
              {title: 'Abwechslungsreiche Sprache (Englisch)', link: '/einfache-phrasen-im-englischen-durch-prazise-ersetzen'},
              {title: 'Gehobenes englisches Vokabular', link: '/gehobenes-englisches-vokabular'}
            ]},
            {title: 'Methodik', lernzettel: [
              {title: 'Zitieren und Zeilenangaben im Englischen', link: '/zitieren-und-zeilenangaben-im-englischen-how-to-quote'},
              {title: 'Stylistic devices', link: '/stylistic-devices'},
              {title: 'Anforderungsbereiche Englisch', link: '/anforderungsbereiche-englisch'},
              {title: 'Mediation im Abitur', link: '/mediation-im-abitur'},
              {title: 'Letter - wichtige Formen für das Abitur', link: '/letter-wichtige-formen-fur-das-abitur'},
              {title: 'Continuation of a fictional text', link: '/continuation-of-a-fictional-text'},
              {title: 'Comment', link: '/comment'},
              {title: 'Artikel im Englisch-Abi', link: '/artikel-im-englisch-abi'},
              {title: 'Analyzing non-fictional texts', link: '/analyzing-non-fictional-texts-aspekte'},
              {title: 'Analyzing fictional texts', link: '/analyzing-fictional-texts'}
            ]},
            {title: 'Inhalte', lernzettel: [
              {title: 'African Americans: Free at Last? Equal at Last?', link: '/african-americans-free-at-last-equal-at-last'},
              {title: 'Science (Fiction) & Technology: Blessing or Curse?', link: '/science-fiction-technology-blessing-or-curse'}
            ]},
            {title: 'Weiteres', lernzettel: [
              {title: 'PowerPoint Presentation: Cryptography – Is our privacy in jeopardy?', link: '/powerpoint-presentation-cryptography-is-our-privacy-in-jeopardy'}
            ]}
          ]},
          {title: 'Mathe', themen: [
            {title: 'Analysis', lernzettel: [
              {title: 'Analysis - Themen', link: '/analysis-themen'},
              {title: 'Bestimmung von Nullstellen', link: '/bestimmung-von-nullstellen'},
              {title: 'Kenntnisse zu bestimmten Funktionen', link: '/kenntnisse-zu-bestimmten-funktionen'},
              {title: 'Ableiten', link: '/ableiten'},
              {title: 'Kurvendiskussion', link: '/kurvendiskussion'},
              {title: 'Tangentengleichung', link: '/tangentengleichung'},
              {title: 'Normalengleichung', link: '/normalengleichung'},
              {title: 'Integrale', link: '/integrale'},
              {title: 'Integration durch Substitution', link: '/integration-durch-substitution'},
              {title: 'Partielle Integration', link: '/partielle-integration'},
              {title: 'Integral und Flächeninhalt', link: '/integral-und-flacheninhalt'},
              {title: 'Uneigentliche Integrale', link: '/uneigentliche-integrale'},
              {title: 'Integral und Rauminhalt', link: '/integral-und-rauminhalt'}
            ]},
            {title: 'Analytische Geometrie', lernzettel: [
              {title: 'Analytische Geometrie - Themen', link: '/analytische-geometrie-themen'},
              {title: 'Beschriftung des Koordinatensystems und eines Vierecks', link: '/beschriftung-des-koordinatensystems-und-eines-vierecks'},
              {title: 'Skalarprodukt', link: '/skalarprodukt'},
              {title: 'Kreuzprodukt', link: '/kreuzprodukt'},
              {title: 'Länge von Vektoren', link: '/lange-von-vektoren'},
              {title: 'Einheitsvektor', link: '/einheitsvektor'},
              {title: 'Gauß-Verfahren', link: '/gauss-verfahren'},
              {title: 'Geraden - Formen und Punktprobe', link: '/geraden-formen-und-punktprobe'},
              {title: 'Lagebeziehungen zweier Geraden zueinander', link: '/lagebeziehungen-zweier-geraden-zueinander'},
              {title: 'Ebenen - Gleichungsformen, Umwandlungen, Spurgeraden und Punke', link: '/ebenen-gleichungsformen-umwandlungen-spurgeraden-und-punke'},
              {title: 'Gegenseitige Lage von Ebenen', link: '/gegenseitige-lage-von-ebenen'},
              {title: 'Gegenseitige Lage von Geraden und Ebenen', link: '/gegenseitige-lage-von-geraden-und-ebenen'},
              {title: 'Abstandsbestimmung Punkt zu Ebene', link: '/abstandsbestimmung-punkt-zu-ebene'},
              {title: 'Abstand Punkt zu Gerade', link: '/abstand-punkt-zu-gerade'},
              {title: 'Abstand Gerade zu Gerade', link: '/abstand-gerade-zu-gerade'},
              {title: 'Schnittwinkel in der analytischen Geometrie', link: '/schnittwinkel-in-der-analytischen-geometrie'},
              {title: 'Kreise und Kugeln in der analytischen Geometrie', link: '/kreise-und-kugeln-in-der-analytischen-geometrie'}
            ]},
            {title: 'Stochastik', lernzettel:[
              {title: 'Themen der Stochastik - Abi', link: '/themen-der-stochastik-abi'},
              {title: 'Mengenlehre', link: '/mengenlehre'},
              {title: 'Baumdiagramm', link: '/baumdiagramm'},
              {title: 'Vierfeldertafel', link: '/vierfeldertafel'},
              {title: 'Bedingte Wahrscheinlichkeit', link: '/bedingte-wahrscheinlichkeit'},
              {title: 'Kombinatorik', link: '/kombinatorik'},
              {title: 'Bernoulli-Experimente', link: '/bernoulli-experimente'},
              {title: 'Hypothesentests', link: '/hypothesentests'},
              {title: 'Hypergeometrische Verteilung', link: '/hypergeometrische-verteilung'},
              {title: 'Konfidenzintervalle bestimmen', link: '/konfidenzintervalle-bestimmen'}
            ]}
          ]},
          {title: 'Physik', themen: [
            {title: 'Wellen', lernzettel: [
              {title: 'Wellen in der Physik - Themen', link: '/wellen-in-der-physik-themen'},
              {title: 'Wichtige Formeln zu Wellen', link: '/wichtige-formeln-zu-wellen'},
              {title: 'Doppler-Effekt', link: '/doppler-effekt'},
              {title: 'Doppelspalt', link: '/doppelspalt'},
              {title: 'Einzelspalt', link: '/einzelspalt'},
              {title: 'Gitter', link: '/gitter'},
              {title: 'Interferenz an dünnen Schichten', link: '/interferenz-an-dunnen-schichten'},
              {title: 'Gesetz von Malus', link: '/gesetz-von-malus'},
              {title: 'Brewster-Winkel', link: '/brewster-winkel'},
              {title: 'Bragg-Bedingung', link: '/bragg-bedingung'},
              {title: 'Farbeindruck unter Winkel', link: '/farbeindruck-unter-winkel'}
            ]},
            {title: 'Felder', lernzettel: [
              {title: 'Felder in der Physik - Themen', link: '/felder-in-der-physik-themen-im-abitur'},
              {title: 'Allgemeine Größen des Stromkreises', link: '/allgemeine-grossen-des-stromkreises'},
              {title: 'Elektrische Felder - allgemein', link: '/elektrische-felder-allgemein'},
              {title: 'Homogene elektrische Felder', link: '/homogene-elektrische-felder'},
              {title: 'Kondensator', link: '/kondensator'},
              {title: 'Magnetfeld einer Spule', link: '/magnetfeld-einer-spule'},
              {title: 'Lorentzkraft', link: '/lorentzkraft'},
              {title: 'Influenz', link: '/influenz'},
              {title: 'Faraday’scher Käfig', link: '/faradayscher-kafig'},
              {title: 'Teilchenbeschleuniger', link: '/teilchenbeschleuniger'},
              {title: 'Geschwindigkeitsfilter', link: '/geschwindigkeitsfilter'},
              {title: 'Massenspektrometer', link: '/massenspektrometer'},
              {title: 'Hall-Effekt', link: '/hall-effekt'},
              {title: 'Bestimmung der Elementarladung nach Millikan', link: '/bestimmung-der-elementarladung-nach-millikan'}
            ]},
            {title: 'Quantenphysik', lernzettel: [
              {title: 'Quantenmechanik - Themen', link: '/quantenmechanik-themen-im-abitur'},
              {title: 'h-Bestimmung mit einer LED', link: '/h-bestimmung-mit-einer-led'},
              {title: 'De-Broglie-Wellenlänge', link: '/de-broglie-wellenlange'},
              {title: 'Photon mit Masse und Impuls', link: '/photon-mit-masse-und-impuls'},
              {title: 'Jönsson-Versuch', link: '/jonsson-versuch'},
              {title: 'Beugung und Interferenz am Kristallgitter', link: '/beugung-und-interferenz-am-kristallgitter'},
              {title: 'Photoeffekt', link: '/photoeffekt'},
              {title: 'Statistische Deutung nach Born', link: '/statistische-deutung-nach-born'},
              {title: 'Heisenbergsche Unschärferelation', link: '/heisenbergsche-unscharferelation'}
            ]},
            {title: 'Atomphysik', lernzettel: [
              {title: 'Atomphysik - Themen', link: '/atomphysik-themen-im-abitur'},
              {title: 'Serien und Energieniveaus', link: '/serien-und-energieniveaus'},
              {title: 'Bohrs Atommodell', link: '/bohrs-atommodell'},
              {title: 'Orbitalmodell und eindimensionaler Potentialtopf', link: '/orbitalmodell-und-eindimensionaler-potentialtopf'},
              {title: 'Quantenzahlen, Pauli-Prinzip und Zuteilung von Elektronen', link: '/quantenzahlen-pauli-prinzip-und-zuteilung-von-elektronen'},
              {title: 'Röntgenstrahlung', link: '/rontgenstrahlung'},
              {title: 'Franck-Hertz-Versuch', link: '/franck-hertz-versuch'},
              {title: 'Compton-Effekt', link: '/compton-effekt'}
            ]}
          ]},
          {title: 'Chemie', themen:[
            {title: 'Wasser', lernzettel: [
              {title: 'Parameter der Wasseranalytik', link: '/parameter-der-wasseranalytik'},
              {title: 'Wasserhärte – Bedeutung und Enthärtung', link: '/wasserharte-bedeutung-und-enthartung'},
              {title: 'Sauerstoffgehalt in Wasser und Bestimmung durch Mangan(II)-sulfat', link: '/sauerstoffgehalt-in-wasser-und-bestimmung-durch-manganii-sulfat'},
              {title: 'Titration – Verfahren, Endpunktbestimmung und Auswertung', link: '/titration-verfahren-endpunktbestimmung-auswertung'},
              {title: 'pH-Regulation im Meer', link: '/ph-regulation-im-meer'},
              {title: 'Stickstoffelimination', link: '/stickstoffelimination'},
              {title: 'Stickstoffverbindungen im Stoffwechsel', link: '/stickstoffverbindungen-im-stoffwechsel'}
            ]},
            {title: 'Farbe', lernzettel: [
              {title: 'Grundlagen Farbe', link: '/grundlagen-farbe'},
              {title: 'Farbtheorie nach Witt', link: '/farbtheorie-nach-witt'},
              {title: 'Fasern', link: '/fasern'},
              {title: 'Azofarbstoffe', link: '/azofarbstoffe'},
              {title: 'Triphenylmethanfarbstoffe', link: '/triphenylmethanfarbstoffe'},
              {title: 'Berliner Blau – Überblick und Herstellung', link: '/berliner-blau-uberblick-und-herstellung'},
              {title: 'PowerPoint Präsentation: Berliner Blau – Überblick und Herstellung', link: '/powerpoint-prasentation-berliner-blau-uberblick-und-herstellung'},
              {title: 'Färben mit Berliner Blau', link: '/farben-mit-berliner-blau'}           
            ]},
            {title: 'Kunststoffe', lernzettel: [
              {title: 'Einteilung von Polymeren - Thermoplasten, Duroplasten, Elastomere', link: '/einteilung-von-polymeren-thermoplasten-duroplasten-elastomere'},
              {title: 'Polykondensation', link: '/polykondensation'},
              {title: 'Herstellung von Polyurethanschaum', link: '/herstellung-von-polyurethanschaum'},
              {title: 'Polymerisation', link: '/polymerisation'}
            ]},
            {title: 'Fette', lernzettel: [
              {title: 'Fette', link: '/fette'}
            ]},
            {title: 'Kohlenhydrate', lernzettel: [
              {title: 'Kohlenhydrate - allgemein - kurz', link: '/kohlenhydrate-allgemein-kurz'},
              {title: 'Monosaccharide', link: '/monosaccharide'},
              {title: 'Disaccharide', link: '/disaccharide'},
              {title: 'Polysaccharide', link: '/polysaccharide'}
            ]},
            {title: 'Aminosäuren und Proteine', lernzettel: [
              {title: 'Aminosäuren', link: '/aminosauren'},
              {title: 'Nachweis und Trennung von Aminosäuren', link: '/nachweis-und-trennung-von-aminosauren'},
              {title: 'Proteine', link: '/proteine'}
            ]}
          ]},
          {title: 'Biologie', themen: [
            {title: 'Genetik', lernzettel: [
              {title: 'DNA - Aufbau und Verpackung', link: '/dna-aufbau-und-verpackung'},
              {title: 'DNA - Aufbau und Verpackung - Kurzzusammenfassung', link: '/dna-aufbau-und-verpackung-kurzzusammenfassung'},
              {title: 'Zellzyklus und Mitose', link: '/zellzyklus-und-mitose'},
              {title: 'Natürliche Replikation der DNA', link: '/naturliche-replikation-der-dna'},
              {title: 'Künstliche Replikation der DNA inkl. Vergleich zur natürlichen', link: '/kunstliche-replikation-der-dna-inkl-vergleich-zur-naturlichen'},
              {title: 'Genetischer Fingerabdruck aus DNA', link: '/genetischer-fingerabdruck-aus-dna'},
              {title: 'Genetik', link: '/genetik'}
            ]},
            {title: 'Stoffwechsel', lernzettel: [
              {title: 'Energie im Organismus', link: '/energie-im-organismus'},
              {title: 'Zusammenfassung zur aeroben Atmung / Zellatmung', link: '/zusammenfassung-zur-aeroben-atmung-zellatmung'},
              {title: 'Atmung – ein Konzentrationsausgleich – Höhentraining', link: '/atmung-ein-konzentrationsausgleich-hohentraining'},
              {title: 'Regulation der Sauerstoffkonzentration im Blut', link: '/regulation-der-sauerstoffkonzentration-im-blut'},
              {title: 'Stoffwechselvorgänge beim Sport', link: '/stoffwechselvorgange-beim-sport'},
              {title: 'Wieso leben große Säugetiere länger als kleine?', link: '/wieso-leben-grosse-saugetiere-langer-als-kleine'}
            ]},
            {title: 'Neurobiologie', lernzettel: [
              {title: 'Aufbau eines Neurons', link: '/aufbau-eines-neurons'},
              {title: 'Aufbau des Nervensystems', link: '/aufbau-des-nervensystems'},
              {title: 'Reizweiterleitung', link: '/reizweiterleitung'},
              {title: 'Wie erhöhen die Ranvier-Schnürringe die Leitungsgeschwindigkeit?', link: '/wie-erhohen-die-ranvier-schnurringe-die-leitungsgeschwindigkeit'},
              {title: 'Informationsübertragung an Synapsen', link: '/informationsubertragung-an-synapsen'},
              {title: 'Beeinflussung der Signalweiterleitung durch Neurotoxine', link: '/beeinflussung-der-signalweiterleitung-durch-neurotoxine'}
            ]}
          ]},
          {title: 'Geschichte', themen: [
            {title: 'Nation', lernzettel: [
              {title: 'Theorien des Nationsbegriffs – Was ist eine Nation?', link: '/theorien-des-nationsbegriffs-was-ist-eine-nation'},
              {title: 'John Locke über den Staat', link: '/john-locke-uber-den-staat'},
              {title: 'Baron de Montesquieu über den Geist der Gesetze', link: '/charles-de-secondat-baron-de-montesquieu-uber-den-geist-der-gesetze'},
              {title: 'Jean-Jacques Rousseau – Prinzipien des Staatsrechts', link: '/jean-jacques-rousseau-prinzipien-des-staatsrechts'},
              {title: 'Deutscher Nationalismus', link: '/deutscher-nationalismus'},
              {title: 'Großdeutsch / Kleindeutsch', link: '/grossdeutsch-kleindeutsch'}
            ]},
            {title: 'Frieden', lernzettel: [
              {title: 'Frieden – Arten / Definitionen und Strömungen', link: '/frieden-arten-definitionen-und-stromungen'},
              {title: 'Kant – Zum ewigen Frieden', link: '/kant-zum-ewigen-frieden'},
              {title: 'Dieter Senghaas` zivilisatorisches Hexagon', link: '/dieter-senghaas-zivilisatorisches-hexagon'},
              {title: 'Friede nach Karl Jaspers', link: '/friede-nach-karl-jaspers'},
              {title: 'Westfälischer Friede', link: '/westfalischer-friede'},
              {title: 'Wiener Kongress', link: '/wiener-kongress'},
              {title: 'Versailler Vertrag', link: '/versailler-vertrag'}
            ]},
            {title: 'Revolutionen', lernzettel: [
              {title: 'Glorious Revolution', link: '/glorious-revolution'},
              {title: 'Amerikanische Revolution', link: '/amerikanische-revolution'},
              {title: 'Französische Revolution', link: '/franzosische-revolution'},
              {title: '48er Revolution', link: '/48er-revolution'}
            ]},
            {title: 'Entstehung des Deutschen Reiches', lernzettel: [
              {title: 'Hintergrund der Entstehung des Deutschen Reiches', link: '/geschichtlicher-hintergrund-der-entstehung-des-deutschen-reiches'},
              {title: 'Die Verknüpfung von Preußen und der deutschen Nation', link: '/die-verknupfung-von-preussen-und-der-deutschen-nation'}
            ]},
            {title: 'Ausbruch des Ersten Weltkriegs', lernzettel: [
              {title: 'Der Ausbruch des Ersten Weltkriegs', link: '/der-ausbruch-des-ersten-weltkriegs'},
              {title: 'PowerPoint Präsentation: Der Ausbruch des Ersten Weltkriegs', link: '/powerpoint-prasentation-der-ausbruch-des-ersten-weltkriegs'}
            ]},
            {title: 'DDR', lernzettel: [
              {title: 'Der ,,Rückzug ins Private“ – war die DDR eine entpolitisierte Gesellschaft?', link: '/der-ruckzug-ins-private-war-die-ddr-eine-entpolitisierte-gesellschaft'},
              {title: 'Handout: Der ,,Rückzug ins Private“ – war die DDR eine entpolitisierte Gesellschaft?', link: '/handout-der-ruckzug-ins-private-war-die-ddr-eine-entpolitisierte-gesellschaft'},
              {title: 'PowerPoint Präsentation: Der ,,Rückzug ins Private“ – war die DDR eine entpolitisierte Gesellschaft?', link: '/powerpoint-prasentation-der-ruckzug-ins-private-war-die-ddr-eine-entpolitisierte-gesellschaft'}
            ]}
          ]},
          {title: 'Wirtschaft / Politik', themen:[
            {title: 'Methodik', lernzettel: [
              {title: 'Aufbau eines politischen Urteils', link: '/aufbau-eines-politischen-urteils'},
              {title: 'Urteilsmodell nach Kayser / Hagemann', link: '/urteilsmodell-nach-kayser-hagemann'},
              {title: 'Analyse einer Karikatur', link: '/analyse-einer-karikatur'},
              {title: 'Analyse einer Statistik', link: '/analyse-einer-statistik'}
            ]},
            {title: 'EU', lernzettel: [
              {title: 'Geschichte der EU', link: '/geschichte-der-eu'},
              {title: 'Beitritte in die EU (Tabelle)', link: '/beitritte-in-die-eu-tabelle'},
              {title: 'Beitrittskriterien der EU / Kopenhagener Kriterien', link: '/beitrittskriterien-der-eu-kopenhagener-kriterien'},
              {title: 'Institutionen der EU', link: '/institutionen-der-eu'},
              {title: 'Wichtige Verträge der EU', link: '/wichtige-vertrage-der-eu'},
              {title: 'Supranationalität / Intergouvernementalismus in der EU', link: '/supranationalitat-intergouvernementalismus-in-der-eu'},
              {title: 'Europäische Integration', link: '/europaische-integration'},
              {title: 'Einige Kritikpunkte an der EU', link: '/einige-kritikpunkte-an-der-eu'}
            ]},
            {title: 'Internationale Politik', lernzettel: [
              {title: 'Internationale Beziehungen', link: '/internationale-beziehungen'},
              {title: 'Weltordnungsmodelle', link: '/weltordnungsmodelle'},
              {title: 'Sicherheitsdilemma', link: '/sicherheitsdilemma'},
              {title: 'Das System internationaler Akteure', link: '/das-system-internationaler-akteure'},
              {title: 'Machtkonstellationen in der internationalen Politik', link: '/machtkonstellationen-in-der-internationalen-politik'},
              {title: 'Wandel des Sicherheitsbegriffs', link: '/wandel-des-sicherheitsbegriffs'},
              {title: 'Krieg', link: '/krieg'}
            ]},
            {title: 'Medien', lernzettel: [
              {title: 'Rechtliche Stellung der Medien', link: '/rechtliche-stellung-der-medien'},
              {title: 'Das Verhältnis Medien – Politik', link: '/das-verhaltnis-medien-politik'},
              {title: 'Funktionen und Rolle der Medien in der Demokratie', link: '/funktionen-und-rolle-der-medien-in-der-demokratie'},
              {title: 'Politikvermittlung durch Medien', link: '/politikvermittlung-durch-medien'}
            ]},
            {title: 'Aufgaben', lernzettel: [
              {title: 'Frontex – ein gerechtfertigtes Programm?', link: '/frontex-ein-gerechtfertigtes-programm'},
              {title: 'Stellt 9/11 einen zeitgeschichtlichen Einschnitt dar?', link: '/stellt-911-einen-zeitgeschichtlichen-einschnitt-dar'},
              {title: 'Fiktive schwedische Stellungnahme zur Flüchtlingskrise', link: '/fiktive-schwedische-stellungnahme-zur-fluchtlingskrise'}
            ]}
          ]},
          {title: 'Musik', themen: [
            {title: 'Oper', lernzettel: [
              {title: 'Formen der Oper', link: '/formen-der-oper'},
              {title: 'Bestandteile der Oper', link: '/bestandteile-der-oper'},
              {title: 'Aufbau der Analyse einer Arie', link: '/aufbau-der-analyse-einer-arie'},
              {title: 'Zauberflöte von Mozart', link: '/zauberflote-von-mozart'},
              {title: 'Rheingold von Wagner', link: '/rheingold-von-wagner'},
              {title: 'Analyse zur ,,Arie der Königin der Nacht"', link: '/analyse-zu-arie-der-konigin-der-nacht-aus-mozarts-zauberflote'}
            ]},
            {title: 'Romantik', lernzettel: [
              {title: 'Analyse zu Frühlingtraum aus Schuberts Winterreise', link: '/analyse-zu-fruhlingtraum-aus-schuberts-winterreise'},
              {title: 'Analyse zu Rachmaninows Prélude in G-Moll', link: '/analyse-zu-rachmaninows-prelude-in-g-moll'}
            ]},
            {title: 'Expressionismus', lernzettel: [
              {title: 'Expressionismus in der Musik', link: '/expressionismus-in-der-musik'},
              {title: 'Zwölftonmethode', link: '/zwolftonmethode'},
              {title: 'Arnold Schönberg und die zweite Wiener Schule', link: '/arnold-schonberg-und-die-zweite-wiener-schule'}
            ]}
          ]},
          {title: 'Tipps', themen: [
            {title: 'Allgemein', lernzettel: [
              {title: 'Erfolgreich in der Oberstufe sein', link: '/erfolgreich-in-der-oberstufe-sein'}
            ]}
          ]}
            ]
    }),
    props: {
      source: String
    },
    methods: {
      navigate: function(){
        if(this.$router.currentRoute.path!=='/funktionen/suche'){
        this.$router.push('/funktionen/suche')
        } else{
        this.$router.go(-1)
        }
      },
      print: function(){
        try {
          // Print for Safari browser
          document.execCommand('print', false, null)
        } catch {
          window.print()
        }
      },
      sharable: function(){
        if(navigator.share){
          return true;
        } else {
          return false;
        }
      },
      share: function(){
        if(navigator.share){
          navigator.share({
            url: "https://lernzettel.org" + this.$router.currentRoute.path
          })
        }
      }
    },
    metaInfo: {
      title: 'Lernzettel',
      // all titles will be injected into this template
      titleTemplate: '%s'
    },
    created(){
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()

    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    }
  }
</script>
